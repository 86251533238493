

















































































import Vue from 'vue'
import Logo from '@/components/Logo.vue'
import {
  mapActions
} from 'vuex'

export default Vue.extend({
  name: 'Auth',

  components: {
    Logo
  },

  data () {
    return {
      form: {
        email: null,
        password: null
      },
      loading: false,
      invalidCredentials: false
    }
  },

  computed: {
    isValid () {
      return this.$refs.observer.validate()
    }
  },

  watch: {
    form: {
      deep: true,
      handler () {
        this.invalidCredentials = false
      }
    }
  },

  methods: {
    ...mapActions('auth', [
      'login'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return

      this.authorization({
        email: this.form.email,
        password: this.form.password
      })
    },

    async authorization ({ email, password }) {
      try {
        this.loading = true
        await this.login({ email, password })
        this.$router.push('/')
      } catch (error) {
        // this.$toast.error(error.response.data.error_sms_code.http_body)
        this.invalidCredentials = true
      } finally {
        this.loading = false
      }
    }
  }
})
