import { render, staticRenderFns } from "./Auth.vue?vue&type=template&id=17add95a&scoped=true&lang=pug&"
import script from "./Auth.vue?vue&type=script&lang=ts&"
export * from "./Auth.vue?vue&type=script&lang=ts&"
import style0 from "./Auth.vue?vue&type=style&index=0&id=17add95a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17add95a",
  null
  
)

export default component.exports