var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth"},[_c('div',{staticClass:"auth__image"}),_c('div',{staticClass:"auth__content"},[_c('Logo',{staticClass:"auth__logo"}),_c('div',{staticClass:"auth__title"},[_vm._v("Вход")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth__form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Логин","rules":{ required: true, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"auth__form-group",attrs:{"label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","name":"email","type":"email","placeholder":"Логин","state":errors[0] ? false : null,"aria-describedby":"email-feedback"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('b-form-invalid-feedback',{attrs:{"id":"email-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Пароль","rules":{ required: true, min: 6 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"auth__form-group",attrs:{"label-for":"password"}},[_c('b-form-input',{attrs:{"id":"password","name":"password","type":"password","placeholder":"Пароль","state":errors[0] ? false : null,"aria-describedby":"password-feedback"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-form-invalid-feedback',{attrs:{"id":"password-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,true)}),_c('div',{staticClass:"auth__form-footer"},[(_vm.invalidCredentials)?_c('div',{staticClass:"auth__response"},[_vm._v("Указан неверный логин или пароль")]):_vm._e(),_c('b-button',{staticClass:"auth__submit",attrs:{"disabled":invalid || _vm.loading,"type":"submit","variant":"primary"}},[_vm._v("Войти")])],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }